import {Inject, Injectable} from '@angular/core';
import {AdminRequestsService} from './admin-requests.service';
import {OFFERS_CONFIG} from '../../offers/config/offers-config';
import {STRIPE_SESSION_CREATE_FAILED} from '../../auth/constants/strings';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {EventsService} from "./events.service";
import {HttpHeaders} from '@angular/common/http';
import {NotificationsService} from "./notifications.service";

@Injectable({
    providedIn: 'root'
})
export class OffersHandlerService {

    constructor(
        private adminRequestsService: AdminRequestsService,
        private router: Router,
        private notifier: NotificationsService,
        private eventsService: EventsService,
    ) {

    }

    /**
     * user buy with link from bigvu website, usually already exist
     * @param offerKey key to find offer from json
     */
    handleStripeSessionWithOffer = async (offerKey: string): Promise<void> => {
        const offerDetails = OFFERS_CONFIG[offerKey];
        if (offerDetails) {
            await this.handleStripeSession(offerDetails, undefined, offerKey)
        } else {
            throw "This offer is no longer available. Redirecting back to the main page ...";
        }
    }

    /**
     * user buy from pricing screen with dialog or stripe page
     * @param planName plan name from offer
     * @param currency currency from offer
     * @param tier tiers (amount of users) create session  then calculate price with stripe
     * @param successPath path from app
     * @param cancelPath pah from app
     * @param coupon
     * @param paywallTrigger = used for event (to trace how user ended on pricing screen / payment)
     * @param addTrial
     */
    handleStripeSessionWithoutCoupon = async (planName: string,
                                              currency: string,
                                              tier?: number,
                                              successPath ?: string,
                                              cancelPath?: string,
                                              coupon?: string,
                                              paywallTrigger?: string,
                                              addTrial: boolean = false
    ): Promise<void> => {
        if (planName && currency) {
            const baseUrlCancel = `https://${location.host}/${successPath}?dismiss={true}`
            const cancelUrl = paywallTrigger ? `${baseUrlCancel}&paywallTrigger=${paywallTrigger}` : baseUrlCancel;

            const baseSuccessUrl = `https://${location.host}/${cancelPath}?sessionId={CHECKOUT_SESSION_ID}`;
            const successUrl = paywallTrigger ? `${baseSuccessUrl}&paywallTrigger=${paywallTrigger}` : baseSuccessUrl;

            const offerDetails = {
                planName: planName,
                currency: currency.toLowerCase(),
            }

            if (tier) {
                offerDetails['tier'] = tier;
            }

            if (coupon) {
                offerDetails['coupon'] = coupon;
            }

            if (addTrial) {
                offerDetails['addTrial'] = true;
            }

            await this.handleStripeSession(
                offerDetails,
                undefined,
                undefined,
                cancelUrl,
                successUrl)
        } else {
            throw "This offer is no longer available. Redirecting back to the main page ...";
        }
    }

    /**
     * user gets email with link and token to make api call
     * @param token to allow user make api call
     */
    handleStripeSessionWithToken = async (token: string): Promise<void> => {
        if (token) {
            const headers = new HttpHeaders()
                .append('Authorization', `Bearer ${atob(token)}`);
            await this.handleStripeSession({}, headers, 'desk-mail')
        } else {
            throw "This offer is no longer available. Redirecting back to the main page ...";
        }
    }

    private handleStripeSession = async (offerDetails: any, headers: HttpHeaders = null, eventIdentifier?: string, cancelUrl?: string, successUrl?: string): Promise<void> => {
        try {
            const stripeData = {
                ...offerDetails
            }
            if (cancelUrl) {
                stripeData['cancelUrl'] = cancelUrl;
            }
            if (successUrl) {
                stripeData['successUrl'] = successUrl;
            }
            const data = await this.adminRequestsService.createStripeSession(stripeData, headers);
            if (data && data.url) {
                window.location.href = data.url;
            }

            this.eventsService.log(this.eventsService.events.payment.checkoutStart, {
                ...offerDetails,
                key: eventIdentifier || offerDetails.planName
            });
        } catch (error) {
            let msg = ""
            if (error.responseJSON.code === "10006/2") {
                msg = "Can't checkout when user already subscribed to renewing plan";
            } else {
                msg = STRIPE_SESSION_CREATE_FAILED
            }
            this.notifier.error(msg);

            this.eventsService.log(this.eventsService.events.payment.checkoutStartFailed, {
                ...offerDetails,
                key: eventIdentifier || offerDetails.planName
            });

            this.router.navigate([environment.globals.firstAppPage]);
        }
    }
}